import sys from 'system-components'

// import { ReactComponent as TechLogos } from '../../../assets/img/logo-stickers.svg'
import { ReactComponent as TechLogos1 } from '../../../assets/img/logo-stickers-1.svg'
import { ReactComponent as TechLogos2 } from '../../../assets/img/logo-stickers-2.svg'

export const TechLogosBG = sys({
  is: TechLogos1,
}).extend`
  color: #fff;
  max-width: 100%;
  max-height: 100%;
  /* transform: rotateX(60deg) rotateZ(-45deg) scale(1.2); */
  /* position: absolute; */
  /* height: 100%; */
  /* opacity: 0.5; */
`

TechLogosBG.displayName = 'TechLogosBG'

export const TechLogosBG2 = sys({
  is: TechLogos2,
}).extend`
  color: #fff;
  max-width: 100%;
  max-height: 100%;
  /* transform: rotateX(60deg) rotateZ(-45deg) scale(1.2); */
  /* position: absolute; */
  /* height: 100%; */
  /* opacity: 0.5; */
`
TechLogosBG2.displayName = 'TechLogosBG2'
