import React from 'react'
import styled from 'styled-components'
import REGL from 'regl'
import * as vorostars from './scenes/vorostars'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background-image: linear-gradient(-179deg, #3c3859 0%, #76238e 100%); */
  background-color: #000;
  z-index: -1;
  overflow: hidden;
`

class Scene {
  // constructor(canvas) {
  // 	this.canvas = canvas
  // 	this.regl = REGL({ canvas: this.canvas })
  // 	this.renderer = vorostars.createRenderer(this.regl)

  // 	this.lastWidth = null
  // 	this.lastHeight = null
  // 	this.maxTextureSize = this.regl.limits.maxTextureSize
  // }

  constructor(container) {
    // this.canvas = canvas
    this.regl = REGL({ container, pixelRatio: 1, attributes: { antialias: false }})
    // this.regl = REGL({ container, pixelRatio: 1 })
    // this.regl = REGL({ container })
    this.renderer = vorostars.createRenderer(this.regl)
    // this.maxTextureSize = this.regl.limits.maxTextureSize
  }

  // render() {
  // 	const { width, height } = this.canvas
  // 	const viewport = {
  // 		x: 0,
  // 		y: 0,
  // 		width,
  // 		height
  // 	}
  // 	// const scale = Math.min(width, height)

  // 	if (width !== this.lastWidth || height !== this.lastHeight) {
  // 		this.lastWidth = width
  // 		this.lastHeight = height
  // 	}

  // 	console.log(width, height)
  // 	this.renderer({
  // 		// resolution: [scale, scale],
  // 		resolution: [width, height],
  // 		viewport
  // 	})
  // }
  render(width, height) {
    const viewport = {
      x: 0,
      y: 0,
      width,
      height
    }
    // const scale = Math.min(width, height)

    this.renderer({
      // resolution: [scale, scale],
      resolution: [width, height],
      viewport
    })
  }

  renderFrame() {
    this.tick = this.regl.frame((context) => {
      // console.log(context.drawingBufferWidth, context.drawingBufferHeight)
      // this.render()
      this.render(context.drawingBufferWidth, context.drawingBufferHeight)
    })
  }
}

export class StarField extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    // this.canvas = React.createRef()
  }

  updateDimensions = () => {
    const container = this.container.current
    const dim = container.getBoundingClientRect()
    const canvas = this.canvas.current
    canvas.width = dim.width
    canvas.height = Math.ceil(dim.height)
  }

  componentDidMount() {
    // this.updateDimensions()
    // const canvas = this.canvas.current
    // window.addEventListener('resize', this.updateDimensions)

    // this.scene = new Scene(canvas)
    this.scene = new Scene(this.container.current)
    this.scene.renderFrame()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  // componentWillUnmount () {
  //   // Remove view??
  // }

  render() {
    return (
      <Container innerRef={this.container}>
        {/* <canvas ref={this.canvas} /> */}
      </Container>
    )
  }
}

export default StarField
