import React from 'react'
import styled from 'styled-components'

import { Img, Link } from 'components/ui'
import { IsoPhone } from 'components/blocs'

import MazeSVG from 'assets/img/bg/dark-maze.svg'

const Holder = styled.div`
  display: inline-block;
  width: 100vw;
  height: 35vw;
  overflow: hidden;
  position: relative;
  background: radial-gradient(#3a0a4a, #171121);
  backface-visibility: hidden;
  transform-style: preserve-3d;
`

const Maze = styled.div`
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  /* z-index: -1; */
  background: url(${MazeSVG}) 0 0 repeat;
  background-size: 76px 44px;
`

const Grid = styled.div`
  display: grid;
  width: 100vw;
  /* Number of columns (5vw) */
  grid-template-columns: repeat(auto-fill, 5vw);
  grid-auto-rows: 5.773502691896257vw;
  /* size * 1/Math.cos(Math.PI/6)vw */
  backface-visibility: hidden;
  transform-style: preserve-3d;

  &.left {
    position: absolute;
    transform-origin: 0 0;
    transform: skewY(-30deg);

    div {
      color: #ff0;
      border: #ff0 1px solid;

      &.project {
        background-color: white;
        transform: translateY(0px);
        margin: 10px;
      }

      &.title {
        background-color: purple;
        color: white;
      }
    }
  }

  &.right {
    position: absolute;
    transform-origin: 100vw 0;
    transform: skewY(30deg);

    div {
      color: #f0f;
      border: #f0f 1px solid;
      text-align: right;
    }
  }

  &.bottom {
    position: absolute;
    width: 600vw;
    transform-origin: 500vw 0;
    transform: translatex(-500vw) rotatez(-60deg) skewY(30deg);
    backface-visibility: hidden;
    transform-style: preserve-3d;

    div {
      color: #00FFCB;
      text-align: center;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      perspective: 3000px;

      &.title {
        grid-column: 102 / 105;
        grid-row: 3 / 7;
        font-size: 1.3vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @keyframes prAppear {
        from {
          opacity: 0.1;
        }
        to {
          opacity: 1;
        }
      }
      @keyframes prDropdown {
        from {
          visibility: visible;
          transform: translate3d(4vw, -4vw, 0) scale(0.8);
        }
        to {
          visibility: visible;
          transform: translate3d(0, 0, 0) scale(0.8);
        }
      }

      &.project {
        position: relative;
        visibility: hidden;
        transform: scale(0.8);
        transform-origin: center;
        animation: prAppear .2s linear none, prDropdown .2s linear forwards;

        &.project1 { animation-delay: 4.4s; }
        &.project2 { animation-delay: 4.6s; }
        &.project3 { animation-delay: 4.8s; }
        &.project4 { animation-delay: 5.0s; }
        &.project5 { animation-delay: 5.2s; }
        &.project6 { animation-delay: 5.4s; }

        &::before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 5px;
          right: 5px;
          bottom: 5px;
          left: 5px;
          opacity: 0.4;
          background: rgba(0,0,0,0.8);
          box-shadow: 0 0 0 0 rgba(0,0,0,0.8);
          transform: translateZ(-1px) scale(0.95);
          transition: all 0.3s cubic-bezier(.23,1,.32,1);
          backface-visibility: hidden;
        }

        .layer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: scale(0.98);
          transition: all 0.3s cubic-bezier(.23,1,.32,1);
          transform-origin: center;
          opacity: 0.6;

          &:nth-child(1) {
            background-color: #ac5cf5;
          }
          &:nth-child(2) {
            background-color: #5db4eb;
          }
          &:nth-child(3) {
            background-color: #5debb4;
          }
        }

        ${Img} {
          width: 100%;
          transition: all 0.3s cubic-bezier(.23,1,.32,1);
        }

        &:hover {
          &::before {
            opacity: 0.2;
            box-shadow: 0 0 20px 10px rgba(0,0,0,0.8);
            transform: translateZ(-1px) scale(1);
            transition: all 0.3s cubic-bezier(.18,.89,.32,1.28);
          }
          .layer {
            &:nth-child(1) {
              transform: translate3d(1vw, -1vw, 0) scale(1);
              transition: all 0.3s cubic-bezier(.18,.89,.32,1.28);
            }
            &:nth-child(2) {
              transform: translate3d(2vw, -2vw, 0) scale(1);
              transition: all 0.3s cubic-bezier(.18,.89,.32,1.28);
            }
            &:nth-child(3) {
              transform: translate3d(3vw, -3vw, 0) scale(1);
              transition: all 0.3s cubic-bezier(.18,.89,.32,1.28);
            }
          }
          ${Img} {
            transform: translate3d(4vw, -4vw, 0);
            transition: all 0.3s cubic-bezier(.18,.89,.32,1.28);
          }
        }
      }

      &.project1 {
        grid-column: 102 / 105;
        grid-row: 7 / 9;
      }

      &.project2 {
        grid-column: 102 / 105;
        grid-row: 9 / 11;
      }

      &.project3 {
        grid-column: 102 / 105;
        grid-row: 11 / 13;
      }

      &.project4 {
        grid-column: 99 / 102;
        grid-row: 5 / 7;
      }

      &.project5 {
        grid-column: 99 / 102;
        grid-row: 7 / 9;
      }

      &.project6 {
        grid-column: 99 / 102;
        grid-row: 9 / 11;
      }
    }
  }
`

export const WorkHero = ({ data }) => (
  <Holder>
    <Maze />
    <IsoPhone />
    <Grid className="bottom">
      <div to="#aiwy-2015" className="project project1">
        <div className="layer" />
        <div className="layer" />
        <div className="layer" />
        <Link to="#aiwy-2015">
          <Img fluid={data.aiwy2015GridImg.childImageSharp.fluid} title="All I Wish You Image" alt="All I Wish You - BNP Paribas - 2015" />
        </Link>
      </div>
      <div className="project project2">
        <div className="layer" />
        <div className="layer" />
        <div className="layer" />
        <Link to="#aiwy-2014">
          <Img fluid={data.aiwy2014GridImg.childImageSharp.fluid} title="All I Wish You Image" alt="All I Wish You - BNP Paribas - 2014" />
        </Link>
      </div>
      <div className="project project3">
        <div className="layer" />
        <div className="layer" />
        <div className="layer" />
        <Img fluid={data.arvalGridImg.childImageSharp.fluid} title="Arval Image" alt="Arval - 2016" />
      </div>
      <div className="project project4">
        <div className="layer" />
        <div className="layer" />
        <div className="layer" />
        <Img fluid={data.twoGridImg.childImageSharp.fluid} title="The Wish Odyssey Image" alt="The Wish Odyssey - BNP Paribas - 2016" />
      </div>
      <div className="project project5">
        <div className="layer" />
        <div className="layer" />
        <div className="layer" />
        <Img fluid={data.twmmGridImg.childImageSharp.fluid} title="The Wish Movie Maker Image" alt="The Wish Movie Maker - 2017" />
      </div>
      <div className="project project6">
        <div className="layer" />
        <div className="layer" />
        <div className="layer" />
        <Link to="#bebrave-2016">
          <Img fluid={data.honorGridImg.childImageSharp.fluid} title="Honor - Be Brave" alt="Honor - Be Brave - Honor - 2016" />
        </Link>
      </div>
    </Grid>
  </Holder>
)

export default WorkHero
