import React from 'react'
import { css } from 'styled-components'
import system from 'system-components'
import { Grid } from 'styles/mixins/grid'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Text } from 'rebass'
import { Heading, Button, Link, Section, Box } from 'components/ui'
import { Plasma } from 'components/gl'

// import Triangles from 'assets/bgs/sunburst.svg'

// const Container = system(
//   {
//     is: 'section',
//     position: 'relative',
//     color: 'text.onPrimary',
//     mb: 1,
//   },
//   css`
//     ${'' /* background-image: url(${Triangles});
//     background-size: cover;
//     background-position: 50%; */}
//   `,
// )

const Info = system({
  bg: '#fff',
  color: '#000',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  m: 1,
  p: 2,
})

const Title = system({
  is: 'span',
  fontWeight: 'bold',
})

const Client = system({
  is: 'span',
})

const Card = system(
  {
    // w: 1 / 3,
    bg: '#fff',
    minHeight: '10vh',
    boxShadow: 2,
    position: 'relative',
  },
  css`
    cursor: pointer;
    overflow: hidden;

    ${Info} {
      transition: all 0.3s ease-in-out !important;
      transform: translateY(150%);
    }
    img {
      transition: all 0.6s ease-in-out !important;
      filter: grayscale(0);
    }
    &:hover {
      img {
        filter: grayscale(0);
      }
      ${Info} {
        transform: translateY(0);
      }
    }
    @media (min-width: 900px) {
      img {
        filter: grayscale(1);
      }
    }
  `,
)

export const Showcase = () => (
  <StaticQuery
    query={graphql`
      query ShowcaseQuery {
        p1: file(relativePath: { regex: "/aiwy-2014.png/" }) {
          childImageSharp {
            fluid(maxWidth: 536, maxHeight: 404, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        p2: file(relativePath: { regex: "/honor-2016.png/" }) {
          childImageSharp {
            fluid(maxWidth: 536, maxHeight: 404, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        p3: file(relativePath: { regex: "/aiwy-2015.png/" }) {
          childImageSharp {
            fluid(maxWidth: 536, maxHeight: 404, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        # p3: file(relativePath: { regex: "/arval-2016.png/" }) {
        #   childImageSharp {
        #     fluid(maxWidth: 536, maxHeight: 404, quality: 90) {
        #       ...GatsbyImageSharpFluid_withWebp_tracedSVG
        #     }
        #   }
        # }
      }
    `}
    render={({ p1, p2, p3 }) => (
      <Section>
        <Plasma animate={false} scale={0.04} frame={0.2121} gamma={0.16} drift={0} rot={0.31415} />
        <Box width={1} px={2} py={4} bg="black.a10">
          <Heading.h2 mb={3} textAlign="center">
            Our Work
          </Heading.h2>
          <Text is="p" p={1} textAlign="center">
            Feel free to have a look at some of our previous projects
          </Text>
        </Box>
        <Grid
          w={1}
          position="relative"
          columns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)']}
          g={[3, 4, 4, 4, 5]}
          p={[3, 4, 4, 4, 5]}
        >
          <Card>
            <Link to="/work#aiwy-2014">
              <Img fluid={p1.childImageSharp.fluid} />
              <Info>
                <Client>BNP Paribas</Client>
                <Title>All I Wish You</Title>
              </Info>
            </Link>
          </Card>
          <Card>
            <Link to="/work#bebrave-2016">
              <Img fluid={p2.childImageSharp.fluid} />
              <Info>
                <Client>Honor</Client>
                <Title>Be Brave</Title>
              </Info>
            </Link>
          </Card>
          <Card>
            <Link to="/work#aiwy-2015">
              <Img fluid={p3.childImageSharp.fluid} />
              <Info>
                <Client>BNP Paribas</Client>
                <Title>All I Wish You In One Word</Title>
              </Info>
            </Link>
          </Card>
        </Grid>
        <Text textAlign="center" pb={4}>
          <Button is={Link} to="/work" name="work">
            See more
          </Button>
        </Text>
      </Section>
    )}
  />
)

export default Showcase
