import { responsiveStyle, util, color } from 'styled-system'
import system from 'system-components'

export const gridTemplateColumns = responsiveStyle({
  // React prop name
  prop: 'gridTemplateColumns',
  alias: 'columns',
})

export const gridAutoRows = responsiveStyle({
  // React prop name
  prop: 'gridAutoRows',
  alias: 'columns',
  getter: util.getWidth,
  cssProperty: 'gridAutoRows',
})

// Gaps
export const gridGap = responsiveStyle({
  prop: 'gridGap',
  alias: 'g',
  numberToPx: true,
  key: 'space',
})

export const gridColumnGap = responsiveStyle({
  prop: 'gridColumnGap',
  alias: 'gx',
  numberToPx: true,
  key: 'space',
})

export const gridRowGap = responsiveStyle({
  prop: 'gridRowGap',
  alias: 'gy',
  numberToPx: true,
  key: 'space',
})

export const Grid = system(
  {
    display: 'grid',
    m: 0,
    p: 0,
  },
  'width',
  'position',
  gridTemplateColumns,
  gridAutoRows,
  gridGap,
  gridColumnGap,
  gridRowGap,
  color,
)

export default Grid
