import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import posed, { PoseGroup } from 'react-pose'
import { tween, easing } from 'popmotion'

const StyledWord = styled.div`
  /* display: block; */
`
// const Word = posed.div({
//   preEnter: {
//     // opacity: 0,
//     // y: 16,
//     opacity: ({ pre }) => pre.opacity || 0,
//     y: ({ pre }) => pre.y,
//   },
//   enter: {
//     // opacity: 1,
//     // y: 0,
//     opacity: ({ enter }) => enter.opacity || 1,
//     y: ({ enter }) => enter.y,
//   },
//   exit: {
//     // opacity: 0,
//     // y: -16,
//     opacity: ({ exit }) => exit.opacity || 0,
//     y: ({ exit }) => exit.y,
//   },
// })

// const StyledWordBox = styled.div`
//   display: inline-block;
// `

// const WordBox = posed(StyledWordBox)({
//   resize: {
//     flip: false,
//     beforeChildren: true,
//     afterChildren: false,
//     width: ({ width }) => width,
//     transition: ({ from, to }) => tween({ from, to, ease: easing.backOut }),
//   },
// })

export class Loop extends React.Component {
  state = {
    currentWord: 0,
  }

  constructor(props) {
    super(props)
    this.wordRef = React.createRef()
    this.word = posed(StyledWord)({
      preEnter: this.props.pre,
      enter: this.props.enter,
      exit: this.props.exit,
    })
  }

  nextWord = () => {
    this.setState({
      currentWord: (this.state.currentWord + 1) % React.Children.count(this.props.children),
    })
  }

  componentDidMount() {
    this.tick = setInterval(this.nextWord, this.props.speed)
  }

  componentWillUnmount() {
    if (this.tick != null) clearInterval(this.tick)
  }

  getWidth = () => {
    const ref = this.wordRef.current
    const width = ref ? ref.getBoundingClientRect().width : 'auto'
    console.log(`Get Width: ${width}`)
    return width
  }

  render() {
    const Word = this.word
    // const width = this.getWidth()
    const children = React.Children.toArray(this.props.children)[this.state.currentWord]
    return (
      // <WordBox pose="resize" width={width} poseKey={width}>
      // <span ref={this.wordRef}>
      <PoseGroup preEnterPose="preEnter">
        <Word {...this.props} key={this.state.currentWord}>
          {children}
        </Word>
      </PoseGroup>
      //   </span>
      // </WordBox>
    )
  }
}

Loop.propTypes = {
  speed: PropTypes.number,
  pre: PropTypes.object,
  enter: PropTypes.object,
  exit: PropTypes.object,
}

Loop.defaultProps = {
  speed: 3000,
  pre: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
}

export default Loop
