import React from 'react'
import { Text } from 'rebass'
import sys from 'system-components'

import { Flex, Box as BaseBox, Section, Heading, Subhead } from '../../ui'
import { Plasma } from '../../gl/Plasma'
import { TechLogosBG, TechLogosBG2 } from './TechLogos'

import { ReactComponent as IsoLaptop } from '../../../assets/img/iso-laptop-right.svg'
import { ReactComponent as IsoMobile } from '../../../assets/img/iso-mobile-left.svg'
import { ReactComponent as IsoServer } from '../../../assets/img/iso-server-right.svg'
import { ReactComponent as IsoSocial } from '../../../assets/img/iso-social-right.svg'

// const Box = BaseBox.extend`
//   height: 50vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
// `

// export const BaseBox = sys(
//   {
//     is: ReBaseBox,
//   },
//   'alignContent',
// )
// BaseBox.displayName = 'BaseBox'

export const Box = sys(
  {
    is: BaseBox,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  'alignContent',
  'minHeight',
  'height',
  'flexWrap',
)
Box.displayName = 'Box'

const Img = sys({
  maxHeight: '80%',
  maxWidth: '80%',
})

export class Skills extends React.Component {
  render() {
    return (
      <Section
        key="skills"
        justifyContent="space-around"
        flexDirection="column"
        flexWrap="wrap"
        color="text.onPrimary"
        p={[0, 0, 0]}
        px={[0, 0, 0]}
        pb={[0, 0, 0]}
      >
        {/* <Plasma animate={false} scale={0.25} frame={0.4242} gamma={0.16} drift={0.0} rot={0.31415} /> */}
        <Plasma animate={false} scale={0.055} frame={0.4242} gamma={0.16} drift={0.4} rot={0.31415} />
        {/*
          # Title
          */}
        {/* <BaseBox width={1} px={2} py={4} bg="black.a10"> */}
        <BaseBox width={1} px={2} py={4} bg="black.a10">
          <Heading.h2 mb={3} textAlign="center">
            Experience and skills
          </Heading.h2>
          <Text is="p" p={1} textAlign="center">
            Here's what we can do for you
          </Text>
        </BaseBox>
        {/* <Box width={[1, 1, 1, 2 / 3]} p={2} flexWrap="wrap" minHeight="50vh" flexDirection="row" alignContent="center">
          <BaseBox width={[1, 1 / 2]} display="bloc">
            <TechLogosBG />
          </BaseBox>
          <BaseBox width={[1, 1 / 2]} display="bloc">
            <TechLogosBG2 />
          </BaseBox>
        </Box> */}
        <Flex
          flexWrap="wrap"
          justifyContent="space-around"
          width="100%"
          fontSize="calc( 13px + (18 - 13) * ( (100vw - 200px) / ( 1900 - 200 ) ))"
        >
          {/*
            # Front
            */}
          <Box p={2} width={[1, 1 / 2]} bg="black.a20" order={[2, 1]} height="50vh">
            <div>
              <Subhead mb={1}>Front-end</Subhead>
              <ul>
                <li>React</li>
                <li>WebGL</li>
                <li>Micro-interaction & Animations</li>
                {/* <li>Babel, Webpack and ES7</li> */}
                <li>jQuery</li>
                <li>HTML, JS, CSS</li>
              </ul>
            </div>
          </Box>
          <Box p={2} width={[1, 1 / 2]} bg="white.a20" order={[1, 2]} height="50vh">
            <Img is={IsoLaptop} />
          </Box>
          {/*
            # Social
            */}
          <Box p={2} width={[1, 1 / 2]} bg="white.a20" order={3} height="50vh">
            <Img is={IsoSocial} />
          </Box>
          <Box p={2} width={[1, 1 / 2]} bg="black.a20" order={4} height="50vh">
            <div>
              <Subhead mb={1}>Social</Subhead>
              <ul>
                <li>Facebook & Twitter</li>
                {/* <li>Twitter messages & streams</li> */}
                <li>OAuth</li>
                <li>Open Graph</li>
                <li>Chatbots</li>
              </ul>
            </div>
          </Box>
          {/*
            # Back
            */}
          <Box p={2} width={[1, 1 / 2]} bg="black.a20" order={[6, 5]} height="50vh">
            <div>
              <Subhead mb={1}>Back-end</Subhead>
              <ul>
                <li>Ruby on Rails / Phoenix</li>
                <li>REST & GraphQL APIs</li>
                <li>SQL & NoSQL</li>
                <li>Video & image generation</li>
                <li>High load architectures</li>
                <li>Dedicated bare-metal hosting</li>
              </ul>
            </div>
          </Box>
          <Box p={2} width={[1, 1 / 2]} bg="white.a20" order={[5, 6]} height="50vh">
            <Img is={IsoServer} />
          </Box>
          {/*
            # Mobile
            */}
          <Box p={2} width={[1, 1 / 2]} bg="white.a20" order={7} height="50vh">
            <Img is={IsoMobile} />
          </Box>
          <Box p={2} width={[1, 1 / 2]} bg="black.a20" order={8} height="50vh">
            <div>
              <Subhead mb={1}>Mobile</Subhead>
              <ul>
                <li>React Native</li>
                <li>RubyMotion</li>
                <li>iOS</li>
                <li>Android</li>
              </ul>
            </div>
          </Box>
        </Flex>
      </Section>
    )
  }
}

export default Skills
