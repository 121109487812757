import React from 'react'
import { Link } from 'gatsby'

import system from 'system-components'

import Layout from 'components/layout'

import { Section, Heading, Button } from 'components/ui'
import { StarField } from 'components/gl/StarField'

// import TextLoop from 'react-text-loop'
import Loop from 'components/ux/Loop'

import { Skills, Customers, Showcase } from 'components/blocs'

export const Text = system(
  {
    m: 0,
  },
  'width',
  'space',
  'color',
  'fontSize',
  'fontWeight',
  'textAlign',
  'lineHeight',
)

Text.displayName = 'Text'

// // const Customers = Section.extend`
//   /* background-color: ${props => props.theme.colors.primaryDark}; */
//   /* background-image: linear-gradient(-45deg, #252323, #393737); */
//   /* background-image: linear-gradient(-45deg, #434343 0%, black 100%); */
//   /* background-image: linear-gradient(to top, #09203f 0%, #537895 100%); */
//   /* background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%); */
//   /* background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); */
//   /* background-image: linear-gradient(to right, #868f96 0%, #596164 100%); */
//   /* background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%); */
//   /* background-blend-mode: multiply; */
//   /* background-image: linear-gradient(-45deg, hsla(309.2, 50%, 48.6%, 0.5) 0%,hsla(239.2, 62.9%, 54.5%, 0.4) 100%); */
//   /* background-image: linear-gradient(-45deg,hsla(309.5, 58.6%, 46.5%, 0.4) 0%,hsla(224, 72.4%, 35.5%, 0.4) 100%); */
// // `

const Home = () => (
  <Layout>
    <Section key="hero" minHeight="80vh">
      <Heading.h2 mb={4} textAlign="center">
        Let’s build something together!
      </Heading.h2>
      <Text is="p" mb={3} textAlign="center">
        Madvoid is a <b>development</b> consultancy that brings your digital product ideas to life.<br />
      </Text>
      <Text is="p" mb={4} textAlign="center">
        We are <b>coders</b> that help companies and agencies to create <b>social & interactive experiences</b> that talk to your audience.
      </Text>
      <Text is="div" mb={4} textAlign="center" width="100%">
        <span>Let's bring some magic to your</span>
        <Loop speed={2000} pre={{ opacity: 0, y: 16 }} enter={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -16 }}>
          <b>Web Sites</b>
          <b>Facebook Apps</b>
          <b>Mobile Apps</b>
          <b>Chatbots</b>
          <b>Micro-interactions</b>
          <b>APIs</b>
        </Loop>
      </Text>
      <Button is={Link} to="/work" name="work">
        See what we've made
      </Button>
      <StarField />
    </Section>

    <Showcase />
    <Skills />
    <Customers />
  </Layout>
)

export default Home
