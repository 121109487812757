import sys from 'system-components'
import { ReactComponent as SVG } from '../../assets/img/iso-lines.svg'

export const IsoPhone = sys({
  is: SVG,
}).extend`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  #border {
    stroke-dasharray: 3000,3000;
    stroke-dashoffset: 3000;
    animation: draw 1.5s linear 0.5s;
    animation-fill-mode: both;
  }
  #chips {
    transform-origin: center;
    transform: translate3d(0vw, -4vw, 0);
    opacity: 0;
    animation: appear .5s linear 1s both, dropdown .5s linear 1.1s both;
  }
  #weldings {
    opacity: 0;
    stroke-dasharray: 300,300;
    stroke-dashoffset: 300;
    animation: appear 0.5s linear 1.6s both, draw 1s linear 1.6s both;
  }
  #circuits {
    opacity: 0;
    stroke-dasharray: 3000,3000;
    stroke-dashoffset: 3000;
    animation: appear .3s linear 1.95s both, draw 4s linear 1.95s both;
  }
  #screen {
    opacity: 0;
    animation: appear .5s linear 3.5s both;
  }
  #electrons {
    opacity: 0;
    animation: appear 1s linear 6s both, powerup 100s infinite linear;
  }
  #work {
    transform-origin: center;
    transform: translate3d(0vw, -4vw, 0);
    opacity: 0;
    animation: appear .5s linear 4s both, dropdown .5s linear 4s both;
  }
  #eiffel-tower {
    opacity: 0;
    stroke-dasharray: 3000,3000;
    stroke-dashoffset: 3000;
    animation: appear .3s linear 6.2s both, draw 8s linear 6.2s both;
    & > g {
      fill: rgba(75, 96, 101, 0);
      animation: eiffel .5s linear 7.2s both;
    }
  }
  #blueprints1, #blueprints2 > g {
    filter: drop-shadow( 0px 2px 2px rgba(0,0,0,.3) );
  }
  @keyframes dropdown {
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes appear {
    to {
      opacity: 1;
    }
  }
  @keyframes powerup {
    to {
      stroke-dashoffset: -3000;
    }
  }
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes eiffel {
    to {
      fill: rgba(75, 96, 101, 1);
    }
  }
`
IsoPhone.displayName = 'IsoPhone'
