export class PRNG {
  constructor(seed) {
    this.seed = seed % 2147483647
    if (this._seed <= 0) this._seed += 2147483646
  }

  next() {
    this.seed = (this.seed * 16807) % 2147483647
    return this.seed
  }

  random() {
    // We know that result of next() will be 1 to 2147483646 (inclusive).
    return (this.next() - 1) / 2147483646
  }
}

export default PRNG
