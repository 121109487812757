import React from 'react'
import system from 'system-components'

import { Plasma } from 'components/gl'
import { Flex, Box, Section, Heading, Button, Link } from 'components/ui'
import { Loop } from 'components/ux/Loop'

import { ReactComponent as LogoBnp } from 'assets/logos/bnp-bw-txt.svg'
import { ReactComponent as LogoHonor } from 'assets/logos/honor.svg'
import { ReactComponent as LogoJack } from 'assets/logos/jack.svg'

const FlexBox = Box.extend`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 20vh;

  @media (max-width: 32em) {
    flex-direction: row;
    margin: 16px;
    svg {
      margin: 8px;
    }
  }
`

export const Text = system(
  {
    m: 0,
  },
  'width',
  'space',
  'color',
  'fontSize',
  'fontWeight',
  'textAlign',
  'lineHeight',
)
Text.displayName = 'Text'

export const Customers = () => (
  <Section key="customers" justifyContent="center" minHeight="70vh" p={[3, 4, null, null, 5]}>
    {/* <Plasma grayscale scale={2} frame={42} gamma={0.15} drift={0.5} rot={0.03} brightness={1} lacunarity={1} colour={0.15} /> */}
    {/* <Plasma animate scale={0.02} frame={0.4242} gamma={0.15} drift={0.0} rot={0.314} /> */}
    {/* <Plasma grayscale scale={.1} frame={0.4242} gamma={0.15} drift={0.5} rot={0.314} brightness={1.8} lacunarity={1.6} colour={0.15} /> */}
    {/* <Plasma grayscale scale={.21} frame={0.21} gamma={0.15} drift={0.15} rot={0.31} brightness={2} lacunarity={1.6} colour={0.15} /> */}
    {/* <Plasma grayscale scale={.21} frame={0.21} gamma={0.15} drift={0.15} rot={0.31} brightness={2} lacunarity={1.6} colour={0.15} /> */}
    {/* <Plasma grayscale scale={2} frame={0.21} gamma={0.05} drift={0} rot={0.21} brightness={0.3} lacunarity={4} colour={0.15} /> */}
    {/* <Plasma brightness={0.3} colour={0.2} drift={1} frame={0.21} gamma={0.15} grayscale lacunarity={10} rot={0.7} scale={3}  /> */}
    {/* <Plasma brightness={0.2} colour={0.2} drift={1} frame={0.21} gamma={0.15} grayscale lacunarity={1} rot={0.2} scale={3}  /> */}
    <Plasma
      animate={false}
      brightness={0.3}
      colour={0.2}
      drift={0}
      frame={0.21}
      gamma={0.15}
      grayscale
      lacunarity={2}
      rot={0.2}
      scale={2}
    />
    <Flex flexWrap="wrap" justifyContent="space-around" w={1}>
      <FlexBox width={[1, 2 / 5]} px={2}>
        <Loop speed={3000}>
          <LogoBnp mb={1} width="172" height="40" />
          <LogoHonor mb={1} width="100" height="25" />
          <LogoJack mb={1} width="100" height="77" />
        </Loop>
      </FlexBox>
      <Box width={[1, 1, 3 / 5]} px={[2, 3, null, null, null, 4]}>
        <Box maxWidth={['560px', null, null, null, null, '640px']} width={1} mx="auto">
          <Heading is="h1" mb={4} textAlign={['center', 'left']}>
            We'd love to work with you
          </Heading>
          <Text is="p" p={1} mb={4} textAlign="justify">
            We're an honest, agile team of experts with tons of experience (we've all been working on the web since 2004).
          </Text>
          <Text is="p" p={1} mb={4} textAlign="justify">
            We're small and we like it that way. It gives us the ability to turn on a dime, deliver projects quickly, and dedicate
            extraordinary attention to your assignment.
          </Text>
          <Text is="p" p={1} mb={4} textAlign="justify">
            Whether developping for web sites or applications, we work in strong partnerships with our clients to redefine the digital
            experience around humans and make people's lives easier.
          </Text>
          <Text is="p" p={1} mb={4} textAlign="justify">
            We give all of our clients personal attention. We’ve worked on a wide range of projects each with a unique challenge set and the
            sites we've developed prove that usability can be elegant.
          </Text>
          <Text is="p" p={1} mb={4} textAlign="justify">
            Over 85% of our clients choose to work with us again.
          </Text>
          <Text textAlign="left" pb={4}>
            <Button is={Link} to="/hire-us" name="hire us">
              Let's work together
            </Button>
          </Text>
        </Box>
      </Box>
    </Flex>
  </Section>
)

export default Customers
