/* eslint-disable prefer-destructuring, no-bitwise */
import fragmentShader from '../shaders/cosmos.glsl'

export function generateNoiseTexture(regl, size) {
  const array = new Uint8Array(size * size * 4)

  for (let y = 0; y < size; y++) {
    for (let x = 0; x < size; x++) {
      // 2 Random values
      const r = [Math.round(Math.random() * 255), Math.round(Math.random() * 255)]
      const i = y * 256 * 4 + x * 4
      // Offset
      const x2 = (x + 37) & 255
      const y2 = (y + 17) & 255
      const i2 = y2 * 256 * 4 + x2 * 4
      array[i + 0] = r[0] // R
      array[i + 2] = r[1] // B
      array[i2 + 1] = r[0] // G (= R)
      array[i2 + 3] = r[1] // A (= B)
    }
  }
  return regl.texture({
    // format: 'luminance alpha',
    format: 'rgba',
    width: size,
    height: size,
    wrapS: 'repeat',
    wrapT: 'repeat',
    flipY: false,
    // mag: 'linear',
    // min: 'linear',
    data: array,
  })
}

export function createRenderer(regl) {
  const pgWidth = 256
  const pgTexture = generateNoiseTexture(regl, pgWidth)

  return regl({
    vert: `
      precision highp float;

      attribute vec2 a_position;
      attribute vec2 a_texcoord;

      varying vec2 v_texcoord;

      void main() {
        gl_Position = vec4(a_position, 0.0, 1.0);
        v_texcoord = a_texcoord;
      }
    `,

    frag: fragmentShader,

    attributes: {
      a_position: regl.buffer([-1, -1, 1, -1, 1, 1, -1, -1, 1, 1, -1, 1]),
      a_texcoord: regl.buffer([0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 1]),
    },

    uniforms: {
      u_resolution: regl.prop('resolution'),
      // u_resolution: [regl.context('viewportWidth'), regl.context('viewportHeight')],
      u_time: regl.context('time'),
      u_texture_2: pgTexture,
    },

    framebuffer: regl.prop('destination'),
    viewport: regl.prop('viewport'),
    count: 6,
  })
}
