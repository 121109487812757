import React from 'react'
import styled from 'styled-components'
import REGL from 'regl'
import * as plasma from './scenes/plasma'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background-image: linear-gradient(-179deg, #3c3859 0%, #76238e 100%); */
  background-color: #fff;
  z-index: -1;
  overflow: hidden;
`

class Scene {
  // constructor(canvas) {
  // 	this.canvas = canvas
  // 	this.regl = REGL({ canvas: this.canvas })
  // 	this.renderer = plasma.createRenderer(this.regl)

  // 	this.lastWidth = null
  // 	this.lastHeight = null
  // 	this.maxTextureSize = this.regl.limits.maxTextureSize
  // }

  constructor(container) {
    // this.canvas = canvas
    this.regl = REGL({ container, pixelRatio: 1, attributes: { antialias: false } })
    // this.regl = REGL({ container, attributes: { antialias: false }})
    // this.regl = REGL({ container, pixelRatio: 1 })
    // this.regl = REGL({ container })
    this.renderer = plasma.createRenderer(this.regl)
    // this.maxTextureSize = this.regl.limits.maxTextureSize
  }

  // render(uniforms) {
  // 	this.uniforms = uniforms
  // 	const { width, height } = this.canvas
  // 	const viewport = {
  // 		x: 0,
  // 		y: 0,
  // 		width,
  // 		height
  // 	}
  // 	const scale = Math.min(width, height)

  // 	if (width !== this.lastWidth || height !== this.lastHeight) {
  // 		this.lastWidth = width
  // 		this.lastHeight = height
  // 	}

  // 	this.renderer({
  // 		...uniforms,
  // 		resolution: [scale, scale],
  // 		// resolution: [width, height],
  // 		viewport
  // 	})

  // 	// if (uniforms.animate) {
  // 	// 	this.renderFrame(uniforms)
  // 	// }
  // }

  render(width, height) {
    const viewport = {
      x: 0,
      y: 0,
      width,
      height,
    }
    const scale = Math.min(width, height)

    this.renderer({
      ...this.uniforms,
      resolution: [scale, scale],
      viewport,
    })

    if (!this.uniforms.animate && this.tick) {
      this.tick.cancel()
      this.tick = null
    }
  }

  renderFrame(props) {
    this.uniforms = props
    if (this.tick) {
      this.tick.cancel()
      this.tick = null
    }
    this.tick = this.regl.frame(context => {
      this.render(context.drawingBufferWidth, context.drawingBufferHeight)
    })
  }
}

export class Plasma extends React.Component {
  static defaultProps = {
    grayscale: false,
    colour: 0.15, // 0.01..0.3
    brightness: 2.0,
    lacunarity: 1.6,
    // brightness:
    // lacunarity:
    // // const float _Colour = 0.05;		// 0.01..0.3
    // //const float _Brightness = .1;
    // //const float _Lacunarity = 1.;
  }

  // constructor(props) {
  // 	super(props)
  // 	this.container = React.createRef()
  // 	this.canvas = React.createRef()
  // }

  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  // updateDimensions = () => {
  // 	const container = this.container.current
  // 	const dim = container.getBoundingClientRect()
  // 	const canvas = this.canvas.current
  // 	canvas.width = dim.width
  // 	canvas.height = Math.ceil(dim.height)
  // 	if (this.scene) {
  // 		this.scene.render(this.props)
  // 	}
  // }

  updateDimensions = () => {
    this.scene.renderFrame(this.props)
  }

  // componentDidMount() {
  // 	this.updateDimensions()
  // 	const canvas = this.canvas.current
  // 	window.addEventListener('resize', this.updateDimensions)

  // 	this.scene = new Scene(canvas)
  // 	this.scene.render(this.props)
  // 	if (this.props.animate) {
  // 		this.scene.renderFrame()
  // 	}
  // }

  componentDidMount() {
    this.scene = new Scene(this.container.current)
    window.addEventListener('resize', this.updateDimensions)
    // this.scene.render(this.props)
    this.scene.renderFrame(this.props)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  // componentWillUnmount () {
  //   // Remove view??
  // }

  render() {
    if (this.scene) {
      this.scene.renderFrame(this.props)
    }
    return <Container innerRef={this.container}>{/* <canvas ref={this.canvas} /> */}</Container>
  }
}

export default Plasma
